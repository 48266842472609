import React from 'react'

const Success = () => {

    return (
        <div className="container-fluid overflow-auto">

            <section className="top-section">

                <div className="d-flex flex-column justify-content-center align-items-center">
                    <p>
                        <img className="img-fluid" src={require("../../assets/images/lock_icon.png")} alt="lock-icon"/>
                        <span className="checkout-text">Secure checkout process</span>
                    </p>
                </div>

                <div className="row d-flex flex-column justify-content-center align-items-center">
                    <div className="amount-due-block p-t-20 p-b-20 m-b-100">
                        <h1 className="success-title">Payment successful</h1>
                        <svg width="50" height="50" x="0" y="0" className='success-icon'
                             viewBox="0 0 50 50">
                            <g>
                                <circle cx="25" cy="25" r="25" fill="#25ae88"></circle>
                                <polyline points="38,15 22,33 12,25"></polyline>
                            </g>
                        </svg>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Success;
