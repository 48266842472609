import {executeGet, executePost} from "../Api";
import {generateCaptchaAgain} from "../utils/captchaRegenrator";

export class ManualPayment {

    data;
    token = null;
    attemptToken = null;
    currentHostname = null;

    resolve;
    reject;

    constructor(data) {
        this.data = data;
        this.currentHostname = window.location.origin
            .replace('www.', '')
            .replace('http://', '')
            .replace('https://', '');
        if (window.location.href.indexOf('localhost') > -1) {
            this.currentHostname = 'd99ca581-9679-45cf-abda-fbca5f8f187e.maxcheckout.com';
        }
    }

    pay(e) {
        e.preventDefault();
        return new Promise(async (resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
            if (this.data.paymentMethod === 'cash') {
                await this.sendTransaction(this.data);
            } else {
                let captchaToken = await generateCaptchaAgain();
                let result = await executePost(`payment-attempt?url=${this.currentHostname}`, {
                    name: this.data.firstName + ' ' + this.data.lastName,
                    phone: this.data.phone,
                    amount: this.getAmount(),
                    captchaToken,
                    email: this.data.email,
                    hostname: window.location.hostname,
                    'user-agent': window.navigator.userAgent,
                    address: {
                        address: this.data.address,
                        city: this.data.city,
                        state: this.data.state,
                        zip: this.data.zip,
                        country: this.data.country || 'US'
                    }
                });
                if (result.data.status !== "APPROVED") {
                    this.reject({message: `Your payment was not approved. Please try again.`});
                    return;
                }
                this.attemptToken = result.data.token;
                this.token = await this.retrieveToken();
                if (this.token) {
                    await this.sendToConverge();
                }
            }
        });
    }

    async retrieveToken() {
        try {
            let captchaToken = await generateCaptchaAgain();
            const res = await executeGet("payment-gateway/token?amount=" + this.getAmount()
                + '&isEcheck=' + (this.data.paymentMethod === 'eCheck') + '&attemptToken=' + this.attemptToken + `&captchaToken=${captchaToken}&paymentMethod=${this.data.paymentMethod}`
                + `&url=${this.currentHostname}`);
            return res.data.token;
        } catch (err) {
            if (err?.response?.data?.error) {
                this.reject({message: err.response.data.error});
            } else {
                this.reject(err);
            }
        }
        return null;
    }

    getAmount() {
        if ((this.data.totalAmountDue || this.data.amount) === 0) {
            return "0.01";
        }
        if (isNaN(this.data.totalAmountDue || this.data.amount)) {
            return Number((this.data.totalAmountDue || this.data.amount).replace(/\D/g, '')).toFixed(2);
        } else {
            return Number(this.data.totalAmountDue || this.data.amount).toFixed(2);
        }
    }

    serializeDataForConverge() {
        let result;
        if (this.data.paymentMethod === 'eCheck') {
            result = {
                ssl_amount: this.getAmount(),
                ssl_bank_account_type: this.data.bankAccountType === 'business-account' ? "1" : "0",
                ssl_transaction_type: this.data.amount === 0 ? "ccauthonly" : "ecspurchase",
                ssl_txn_auth_token: this.token,
                ssl_first_name: this.data.firstName,
                ssl_last_name: this.data.lastName,
                ssl_company: this.data.company,
                ssl_email: this.data.email,
                ssl_aba_number: this.data.bankRoutingNumber,
                ssl_bank_account_number: this.data.bankAccountNumber,
                ssl_agree: "1",
                ssl_avs_zip: this.data.zip,
                ssl_city: this.data.city,
                ssl_state: this.data.state,
                ssl_avs_address: this.data.address
            };
        } else if (this.data.paymentMethod === 'card') {
            result = {
                ssl_amount: this.getAmount(),
                ssl_transaction_type: this.data.amount === 0 ? "ccauthonly" : "ccsale",
                ssl_txn_auth_token: this.token,
                ssl_exp_date: this.serializeExpirationDate(),
                ssl_cvv2cvc2: this.data.cvv,
                ssl_card_number: this.data.cardNumber,
                ssl_first_name: this.data.firstName,
                ssl_last_name: this.data.lastName,
                ssl_email: this.data.email,
                ssl_get_token: "Y",
                ssl_add_token: "Y",
                ssl_avs_zip: this.data.zip,
                ssl_city: this.data.city,
                ssl_state: this.data.state,
                ssl_avs_address: this.data.address
            };
        }
        return result;
    }

    serializeExpirationDate() {
        if (this.data.expirationDate.length === 4) {
            return this.data.expirationDate;
        }
        let dates = this.data.expirationDate.split('/');
        if (dates[1].length === 2) {
            return dates[0] + dates[1];
        }
        return dates[0] + dates[1].slice(-2);
    }

    async sendTransaction(data) {
        let captchaToken = await generateCaptchaAgain();
        return executePost(`transaction?url=${this.currentHostname}`, {
            ...data,
            address: {
                address: this.data.address,
                city: this.data.city,
                state: this.data.state,
                zip: this.data.zip,
                country: this.data.country || 'US'
            },
            attemptToken: this.attemptToken,
            cardNumber: null,
            captchaToken,
            cvv: null,
            bankAccountNumber: null,
            userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }).then((res) => {
            this.resolve(res.data.redirectUrl);
        }).catch((err) => {
            if (err?.response?.data?.error) {
                this.reject({message: err.response.data.error});
            } else {
                this.reject(err);
            }
        });
    }

    async sendToConverge() {
        const callback = {
            onError: (error) => {
                console.error(error);
                this.reject({message: error.ssl_result_message});
            },
            onDeclined: (error) => {
                console.error('error #pdo2p3o2');
                console.error(error);
                this.reject({message: error.ssl_result_message ? error.ssl_result_message
                        : <><p>{error.errorName}</p><p>{error.errorMessage}</p><p>Error {error.errorCode}</p></>});
            },
            onApproval: (response) => {
                try {
                    const ssl_card_number = response?.ssl_card_number?.substr(-4) || "";
                    const transactionId = response?.ssl_txn_id || ""
                    const ssl_card_short_description = response?.ssl_card_short_description || "";
                    const ssl_token = response?.ssl_token || "";

                    this.sendTransaction({
                        ssl_card_number,
                        transactionId,
                        ssl_token,
                        ssl_card_short_description,
                        response,
                        ...this.data
                    });

                } catch (err) {
                    this.reject({message: err.error});
                }
            },
        };
        await window.ConvergeEmbeddedPayment.pay(this.serializeDataForConverge(), callback);
    }

}
