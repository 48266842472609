export const generateCaptchaAgain = () => {
    return new Promise((resolve, reject) => {
        window['grecaptcha'].ready(() => {
            window['grecaptcha'].execute('6LdlRgwqAAAAAMN0D-qkvqGoEM6MQANDGk9X1Fmx', {action: 'submit'})
                .then((token) => {
                    resolve(token);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    });
}
