import validator from "validator";

export function FormValidation(form, showError) {

    const formMessages = {
        firstName: [
            {validator: 'isRequired', message: 'First name is required'}
        ],
        lastName: [
            {validator: 'isRequired', message: 'Last name is required'}
        ],
        address1: [
            {validator: 'isRequired', message: 'Address is required'}
        ],
        city: [
            {validator: 'isRequired', message: 'City is required'}
        ],
        state: [
            {validator: 'isRequired', message: 'State is required'}
        ],
        zip: [
            {validator: 'isRequired', message: 'Zip is required'}
        ],
        email: [
            {validator: 'isRequired', message: 'Email is required'},
            {validator: 'isEmail', message: 'Email is not valid'}
        ],
        phone: [
            {validator: 'isRequired', message: 'Phone is required'},
            {validator: 'isMobilePhone', message: 'Phone is not valid'}
        ]
    }

    const creditCardErrorMessages = {
        card: [
            {validator: 'isRequired', message: 'Credit card is required'},
            {validator: 'isCreditCard', message: 'Credit card is not valid'}
        ],
        cvv: [
            {validator: 'isRequired', message: 'CVV2 is required'},
            {validator: 'isNumeric', message: 'CVV2 is not valid'}
        ],
        expirationMonth: [
            {validator: 'isRequired', message: 'Expiration Month is required'},
            {validator: 'isExpirationMonth', message: 'Expiration Month is not valid'}
        ],
        expirationYear: [
            {validator: 'isRequired', message: 'Expiration Year is required'},
            {validator: 'isExpirationYear', message: 'Expiration Year is not valid'}
        ]
    };

    const eCheckErrorMessages = {
        routingNumber: [
            {validator: 'isRequired', message: 'Routing Number is required'}
        ],
        accountNumber: [
            {validator: 'isRequired', message: 'Account Number is required'}
        ],
        agree: [
            {validator: 'isRequired', message: 'You should Agree at conditions'}
        ]
    };

    const cashErrorMessages = {
        cash: [
            {validator: 'isRequired', message: 'Cash is required'}
        ]
    };

    const isExpirationMonth = (expirationMonth) => {
        expirationMonth = expirationMonth && expirationMonth.toString() || '';
        if (expirationMonth > 12 || expirationMonth < 1) {
            return false;
        }
        return true;
    }
    const isExpirationYear = (expirationYear) => {
        expirationYear = expirationYear && expirationYear.toString() || '';
        if (expirationYear.length === 2) {
            expirationYear = +('20' + expirationYear);
        }
        return expirationYear >= new Date().getFullYear();
    }

    const check = (errorMessages) => {
        for (let param in errorMessages) {
            for (let validation of errorMessages[param]) {
                if (validation.validator === 'isRequired' && (form[param] === '' || form[param] === null)) {
                    return showError(validation.message);
                } else if (validation.validator === 'isEmail' && !validator.isEmail(form[param])) {
                    return showError(validation.message);
                } else if (validation.validator === 'isMobilePhone' && !validator.isMobilePhone(form[param])) {
                    return showError(validation.message);
                } else if (validation.validator === 'isNumeric' && !validator.isNumeric(form[param])) {
                    return showError(validation.message);
                } else if (validation.validator === 'isCreditCard' && !validator.isCreditCard(form[param])) {
                    return showError(validation.message);
                } else if (validation.validator === 'isExpirationMonth' && !isExpirationMonth(form[param])) {
                    return showError(validation.message);
                } else if (validation.validator === 'isExpirationYear' && !isExpirationYear(form[param])) {
                    return showError(validation.message);
                }
            }
        }
        return true;
    }

    const validate = (validationType) => {
        if (validationType === 'card') {
            return check(creditCardErrorMessages);
        } else if (validationType === 'eCheck') {
            return check(eCheckErrorMessages);
        } else if (validationType === 'cash') {
            return check(cashErrorMessages);
        } else if (validationType === 'form') {
            return check(formMessages);
        }
    }

    return {
        validate
    };
}
