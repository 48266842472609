import axios from "./api/axiosApi";

const baseURL = process.env.REACT_APP_API;
// const APP_TYPE = process.env.REACT_APP_TYPE;

export const executeGet = (path) => {
  return axios.get(baseURL + path);
};

export const executePut = (path, data) => {
  return axios.put(baseURL + path, data);
};

export const executePost = (path, data) => {
  return axios.post(baseURL + path, data);
};

export const executeDelete = (path, data) => {
  return axios.delete(baseURL + path, data);
};

export const executeUpload = async (path, file) => {
  if (file) {
    const url = baseURL + path; /*CLOUDINARY_URL*/
    let formData = await new FormData();
    await formData.append("upload_preset", "is90xgex");
    await formData.append("file", file);
    return await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  return "";
};
