import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./Reducers";
//convert store factory to a Singleton to use middleware to support async await on redux actions
let store = (function () {
  let initialState = {};
  let instance;

  const enhancer = composeWithDevTools(
    applyMiddleware(thunkMiddleware)
    // other store enhancers if any
  );

  return {
    getInstance: function () {
      if (!instance) {
        instance = createStore(rootReducer, initialState, enhancer);
      }
      return instance;
    },
  };
})();

export default store;
