export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const ACCOUNT_LOGOUT = "ACCOUNT_LOGOUT";

// export const REGISTER_LOADING = "REGISTER_LOADING";
// export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
// export const REGISTER_ERROR = "REGISTER_ERROR";

export const SETTING_LOADING = "SETTING_LOADING";
export const SETTING_SUCCESS = "SETTING_SUCCESS";
export const SETTING_ERROR = "SETTING_ERROR";

