import axios from "axios";

axios.interceptors.request.use(
  config => {
      console.log(config)
      config.baseURL = process.env.REACT_APP_API;
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `bearer ${token}`;
      }
      config.headers.ContentType = 'application/json';
    return config;
  },
  error => Promise.reject(error)
);

export default axios;


// const axiosApi = axios.create({
//   baseURL: baseURL,
// });

// export const setAuthHeader = (token) => {
//   axiosApi.defaults.headers.common.Authorization = `bearer ${
//     token || localStorage.getItem("token")
//   }`;
// };

// axiosApi.defaults.headers.common.Authorization = `bearer ${localStorage.getItem(
//   "token"
// )}`;

// //if a 401 happens, the user will be logged out
// axiosApi.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (error && error.response && error.response.status === 401) {
//       localStorage.removeItem("token");
//       localStorage.removeItem("user");
//     }
//     return Promise.reject(error);
//   }
// );

// // Set the initial header from storage or something (should surround with try catch in actual app)
// setAuthHeader(localStorage.getItem("token"));

// export default axiosApi;
