import React, {useEffect, useState} from 'react';
import './header.css';
import {executeGet} from "../../Api";


const Header = ({logo}) => {


    return (
        <header className="bg-light text-center text-lg-start no-gutter mb-3">
            <nav className="navbar navbar-light">
                <div className="container-fluid">
                    {/* <a className="navbar-brand" href="#">Fixed top</a> */}
                </div>
            </nav>

            <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="globe">

                    {logo && (<img className="painter-logo img-fluid" src={logo} alt="logo"/>)}
                </div>
            </div>

        </header>


    )
}

export default Header;
