// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import { store } from './app/store';
// import { Provider } from 'react-redux';
// import * as serviceWorker from './serviceWorker';

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
// import {  } from "react-router";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./Redux/store";
import HttpsRedirect from "react-https-redirect";

const history = createBrowserHistory();
// const navigate = useNavigate;

ReactDOM.render(
  <>
    <HttpsRedirect>
       <React.StrictMode>
        <Provider store={store.getInstance()}>
          {/* <Router history={history}> */}
          <Router location={history.location} navigator={history}>
            <App />
            <ToastContainer />
          </Router>
        </Provider>
      </React.StrictMode>
    </HttpsRedirect>
  </>,
  document.getElementById("root")
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
