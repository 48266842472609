import {
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  ACCOUNT_LOGOUT,
} from "../../constants";

const initState = {
  isLoginLoad: false,
  isLoginError: false,
  isRegisterLoad: false,
  isRegisterError: false,
  user: {},
};

const authReducer = (state = initState, action) => {
  const { type, payload } = action || {};
  const setInitState = (obj = {}) => {
    return { ...state, ...obj };
  };

  switch (type) {
    case ACCOUNT_LOGOUT:
      return setInitState({
        isAuthenticated: false,
        user: {},
        adminUser: {},
        payerUser: {},
      });
    case LOGIN_LOADING:
      return setInitState({
        isLoginLoad: true,
        isLoginError: false,
        isLoginSuccess: false,
      });
    case LOGIN_SUCCESS:
      return setInitState({
        isLoginLoad: false,
        user: payload,
        isLoginSuccess: true,
      });
    case LOGIN_ERROR:
      return setInitState({
        isLoginError: true,
        isLoginLoad: false,
        isLoginSuccess: false,
      });
    
    
    default:
      return state;
  }
};

export default authReducer;
