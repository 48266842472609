import config from "../config";

export const updateIntakeFormsImmutable = (list, payload) => {
  const data = list.find((d) => d._id.toString() === payload._id.toString());
  if (data) {
    const index = list.findIndex(
      (d) => d._id.toString() === payload._id.toString()
    );

    return [
      ...list.slice(0, index),
      { ...data, ...payload },
      ...list.slice(index + 1),
    ];
  }
  return list;
};

export const updateImmutable = (list, payload) => {
  const data = list.find((d) => d._id === payload._id);
  if (data) {
    const index = list.findIndex((d) => d._id === payload._id);

    return [
      ...list.slice(0, index),
      { ...data, ...payload },
      ...list.slice(index + 1),
    ];
  }
  return list;
};

export const createMarkup = (text) => {
  return { __html: text };
};

export const capitalizeFirstLetter = (text) =>
  text &&
  text
    .split(" ")
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(" ");

export const amountFormatter = (num) =>
  Number(num || 0)
    .toLocaleString("en", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replace(".00", "");
export const amountFormatterWithZero = (num) =>
  Number(num || 0).toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const isExternalRegexClosure = (url) => {
  let domainRe = /https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i;
  if (domainRe.exec(url)) {
    return url;
  } else {
    url = `http://${url}`;
    return url;
  }
};

export const isUrlValid = (userInput) => {
  const res = userInput.match(
    // eslint-disable-next-line no-useless-escape
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  if (res == null) return false;
  else return true;
};

export const getFormattedTime = () => {
  const today = new Date();
  const y = today.getFullYear();
  const m = today.getMonth() + 1;
  const d = today.getDate();
  const h = today.getHours();
  const mi = today.getMinutes();
  const s = today.getSeconds();
  return d + "-" + m + "-" + y + "-" + h + "" + mi + "" + s;
};

export const capitalize = (lower) => {
  return lower.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

export const subDomain = () => {
  return config.APP_TYPE === "local"
    ? ""
    : window.location.host.replace("www.", "").split(".")[0];
};

export const eventUrl = (eventUrl) => {
  return config.APP_TYPE === "local" ? `/${eventUrl}/` : "/";
};

export const allowAlphaNumericSpace = (e) => {
  const code = "charCode" in e ? e.charCode : e.keyCode;
  if (
    code === 32 && // space
    !(code > 47 && code < 58) && // numeric (0-9)
    !(code > 96 && code < 123)
  ) {
    // lower alpha (a-z)
    e.preventDefault();
  }
};

export const keyDownHandlerNumber = (event) => {
  if (
    event.ctrlKey ||
    event.altKey ||
    (47 < event.keyCode && event.keyCode < 58 && event.shiftKey === false) ||
    (95 < event.keyCode && event.keyCode < 106) ||
    event.keyCode === 8 ||
    event.keyCode === 9 ||
    (event.keyCode > 34 && event.keyCode < 40) ||
    event.keyCode === 46
  ) {
    return null;
  } else {
    event.preventDefault();
  }
};

export const isNumberKey = (evt) => {
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
    evt.preventDefault();
    return false;
  }
  return true;
};

export const onCheckEmpty = (data) => {
  if (typeof data === "object") {
    if (JSON.stringify(data) === "{}" || JSON.stringify(data) === "[]") {
      return true;
    } else if (!data) {
      return true;
    }
    return false;
  } else if (typeof data === "string") {
    if (data.trim()) {
      return true;
    }
    return false;
  } else if (typeof data === "undefined") {
    return true;
  } else {
    return false;
  }
};
